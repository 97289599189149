import fetch from "cross-fetch";
import { config } from "./config";
import { UrlParams } from "./types";
import { v5 as uuid5 } from "uuid";
import { UrlSearchParamsParser } from "./urlSearchParamsParser";
import { delayAsync, log } from "./util";
import { addCta, getIp, getTags, postClick } from "./api";

async function getCTAButtons() {
  const TRIES = 100;

  for (let i = 1; i <= TRIES; i++) {
    log(`getting cta buttons try ${i} of ${TRIES}`);

    const btns: HTMLAnchorElement[] = [];

    document
      .querySelectorAll<HTMLAnchorElement>(window.midastrackr.btnSelector)
      .forEach(x => {
        if (x.href) {
          btns.push(x);
        }
      });

    if (!btns.length) {
      log(`no cta button found`);

      if (i < TRIES) {
        await delayAsync(25);
      }
    } else {
      log(`${btns.length} cta button(s) found`);
      return btns;
    }
  }

  return [];
}

function getUrlParams(): UrlParams {
  log("getting url params");

  const parser = new UrlSearchParamsParser(window.location.search);

  const hasQueryTestClick =
    parser.has("testClick") &&
    parser.get("testClick")?.toLowerCase() === "true";

  const hasCookieTestClick =
    window.document.cookie.indexOf("midas_test_click=true") !== -1;

  return {
    utmSource: parser.get("utm_source"),
    utmMedium: parser.get("utm_medium"),
    utmCampaign: parser.get("utm_campaign"),
    utmTerm: parser.get("utm_term"),
    utmContent: parser.get("utm_content"),
    gClid: parser.get("mgclid") || parser.get("gclid"),
    msClid: parser.get("msclid"),
    fbClid: parser.get("fbclid"),
    midasAdId: parser.get("midas_adid"),
    testClick: hasQueryTestClick || hasCookieTestClick,
    userAgent: navigator.userAgent,
    gBraid: parser.get("gbraid"),
    wBraid: parser.get("wbraid")
  };
}

function replaceCTAButtonUrl(
  btns: HTMLAnchorElement[],
  mclid: string,
  tags: string[]
) {
  log("replacing cta urls");

  for (let i = 0; i < btns.length; i++) {
    const btn = btns[i];
    const ctaUrl = btn.getAttribute("href")!;

    log(`btn ${i} old url`, ctaUrl);

    const url = new URL(ctaUrl);
    const originalUrl = new URL(ctaUrl);

    originalUrl.searchParams.forEach((_, key) => {
      if (tags.indexOf(key) !== -1) {
        url.searchParams.delete(key);
        url.searchParams.set(key, mclid);
      }
    });

    const newUrl = url.toString();

    log(`btn ${i} new url`, newUrl);

    btn.setAttribute("href", newUrl);
  }
}

function getAddCtaMethod(mclid: string, btn: HTMLAnchorElement) {
  return async function innerAddCta(e: MouseEvent) {
    e.preventDefault();

    return addCta(mclid, btn);
  };
}

function setCTAButtonListener(btns: HTMLAnchorElement[], mclid: string) {
  if (config.defaultAction() === "action") return;

  log("setting cta buttons listeners");

  for (const btn of btns) {
    const method = getAddCtaMethod(mclid, btn);
    btn.removeEventListener("click", method);
    btn.addEventListener("click", method);
  }
}

function getMclid(ip: string) {
  log("getting mclid");

  const mclid = uuid5(ip, window.midastrackr.pid);

  log("mclid =", mclid);

  return mclid;
}

function setupObserver(mclid: string, normalizedMclid: string, tags: string[]) {
  const observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      mutation.addedNodes.forEach(node => {
        if (!(node instanceof HTMLElement)) return;

        const newCtaButtons: HTMLAnchorElement[] = [];

        if (node.matches(window.midastrackr.btnSelector)) {
          newCtaButtons.push(node as HTMLAnchorElement);
        }

        node.querySelectorAll(window.midastrackr.btnSelector).forEach(child => {
          newCtaButtons.push(child as HTMLAnchorElement);
        });

        tryForwardParameters(newCtaButtons);

        for (const ctaButton of newCtaButtons) {
          setCTAButtonListener([ctaButton], mclid);
          replaceCTAButtonUrl([ctaButton], normalizedMclid, tags);
        }
      });
    }
  });

  observer.observe(document.body, {
    attributes: false,
    characterData: false,
    subtree: true,
    childList: true
  });
}

function toggleLockCTAButtons(btns: HTMLAnchorElement[], lock: boolean) {
  log(`${lock ? "locking" : "unlocking"} cta buttons`);

  for (const btn of btns) {
    if (lock) {
      btn.style.pointerEvents = "none";
    } else {
      btn.style.pointerEvents = "";
    }
  }
}

function tryForwardParameters(links: HTMLAnchorElement[]) {
  if (!links?.length || !window.midastrackr.forwardParameters) return;

  if (!window.location.search) {
    log("tried to forward parameters but no search query found");
    return;
  }

  log(`forwarding parameters for ${links.length} links`);

  const params = new URLSearchParams(window.location.search);

  links.forEach(link => {
    const href = link.getAttribute("href");

    if (!href) return;

    const url = new URL(href);

    params.forEach((value, key) => {
      url.searchParams.set(key, value);
    });

    link.setAttribute("href", url.toString());
  });
}

async function main() {
  log("starting");

  if (config.isBot()) {
    log(`bot detected, user-agent: ${navigator.userAgent}`);
    return;
  }

  if (!window.midastrackr?.pid || !window.midastrackr?.uid) {
    log("missing pid or uid");
    return;
  }

  window.midastrackr.btnSelector ||= "a[href*='mclid']";

  const firstCtaButtons = await getCTAButtons();
  tryForwardParameters(firstCtaButtons);

  toggleLockCTAButtons(firstCtaButtons, true);
  setTimeout(() => {
    toggleLockCTAButtons(firstCtaButtons, false);
  }, 1000);

  const [urlParams, ip, tags] = await Promise.all([
    getUrlParams(),
    getIp(),
    getTags()
  ]);

  if (!tags || !ip) return;

  const mclid = getMclid(ip);
  const normalizedMclid = mclid.replace(/-/g, "");
  const shortMclid = "mds" + normalizedMclid.slice(-10);

  toggleLockCTAButtons(firstCtaButtons, false);

  const defaultAction = config.defaultAction();

  if (defaultAction === "click") {
    void postClick(urlParams, mclid, ip, shortMclid);
  } else if (defaultAction === "action") {
    void addCta(mclid);
  }

  const platformMclid = normalizedMclid;

  const secondCtaButtons = await getCTAButtons();
  setupObserver(mclid, platformMclid, tags);

  setCTAButtonListener(secondCtaButtons, mclid);

  const dontReplaceMclid =
    window.document.cookie.indexOf("midas_dont_replace_mclid=true") !== -1;

  if (!dontReplaceMclid) {
    replaceCTAButtonUrl(secondCtaButtons, platformMclid, tags);
  }

  window.midastrackr.done = true;
  window.midastrackr.mclid = mclid;
}

main();
