import { config } from "./config";
import { UrlParams } from "./types";
import { log } from "./util";

export async function getIp() {
  log("getting ip");

  const getIpUrl = new URL("network/ip", config.api());
  const response = await fetch(getIpUrl);
  const data = await response.text();

  log("get ip response", data);

  return data;
}

export async function postClick(
  urlParams: UrlParams,
  mclid: string,
  ip: string,
  shortMclid: string
) {
  log("posting click");

  const postClickUrl = new URL("tracking/click", config.api());
  const body = {
    projectId: window.midastrackr.pid,
    clickedAt: new Date().toISOString(),
    landingPageUrl: window.location.href,
    mclid,
    shortMclid,
    ip,
    ...urlParams
  };

  const postClickResponse = await fetch(postClickUrl, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" }
  });

  if (postClickResponse.status === 429) {
    log("too many requests");
    return "too-many-requests";
  }

  const data = await postClickResponse.text();

  log("post click response", data);

  if (!data) {
    log("could not post click");
    return;
  }

  return data;
}

export async function addCta(mclid: string, btn?: HTMLAnchorElement) {
  const url = new URL("tracking/cta", config.api());
  url.searchParams.set("projectId", window.midastrackr.pid);
  url.searchParams.set("midasClid", mclid);

  try {
    const resp = await fetch(url, { method: "POST" });
    const data = await resp.text();

    log("cta response", data);
  } finally {
    if (btn) {
      window.location.href = btn.href;
    }
  }
}
